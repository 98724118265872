<template>
  <base-mobile-list class="services-mobile-list" :items="items">
    <template v-slot:item="{ item }">
      <div class="mt-6">
        <v-btn
          class="services-mobile-list__name text-body-1 font-weight-medium px-0 text-none"
          text
          @click="showDetailInfo(item)"
        >
          {{ item.name }}
        </v-btn>
      </div>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="12" class="pr-1">
            <view-list-field :label="$t('services.some_description')">
              {{ item.shortDescription }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="12" class="pr-1">
            <view-list-field :label="$t('services.contractor')">
              {{ item.contractor }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="12" class="pr-1">
            <view-list-field :label="$t('services.projects')">
              {{ joinWithLimit(item.accessibleForProjects, 4) }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="d-flex flex-row mt-6">
        <p class="flex-grow-1 services-mobile-list__initial-amount">
          {{ item.initialAmount }}
        </p>
        <div class="d-flex align-end text-caption text--secondary">
          <div class="d-flex align-center">
            {{ $t('label.select') }}

            <base-checkbox
              :input-value="getSelectedStatus(item)"
              class="services-mobile-list__checkbox ml-1 pt-0 mt-0"
              hide-details
              @change="select(item)"
            />
          </div>
        </div>
      </div>
    </template>
  </base-mobile-list>
</template>

<script>
// Components
import BaseMobileList from '@/components/BaseMobileList.vue';
import ViewListField from '@/components/ViewListField.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';

// utils
import { createModelWrapper } from '@/utils/components';
import joinWithLimit from '@/utils/joinWithLimit';
import { separateThouthands } from '@/utils/priceFormatting';

export default {
  name: 'ServicesMobileList',

  components: { BaseMobileList, ViewListField, BaseCheckbox },

  model: {
    prop: 'selectedItems',
    event: 'update:selectedItems',
  },

  props: {
    items: { type: Array, required: true },
    selectedItems: { type: Array, default: () => [] },
  },

  data() {
    return {
      isSelected: false,
    };
  },

  computed: {
    selectedItemsModel: createModelWrapper('selectedItems', 'update:selectedItems'),
  },

  methods: {
    getSelectedStatus(item) {
      return this.selectedItemsModel.findIndex(usage => usage.id === item.id) !== -1;
    },

    select(item) {
      if (this.getSelectedStatus(item)) {
        const filteredValue = this.selectedItemsModel.filter(usage => usage.id !== item.id);

        this.selectedItemsModel = filteredValue;
        return;
      }

      this.selectedItemsModel = [...this.selectedItems, item];
    },

    joinWithLimit,
    separateThouthands,

    showDetailInfo(item) {
      this.$emit('open', item);
    },
  },
};
</script>

<style lang="scss">
.services-mobile-list {
  &__name {
    color: $--primary-color !important;
    min-width: 0 !important;
  }

  &__initial-amount {
    font-weight: $--font-weight-bold;
    font-size: 16px;
    line-height: 24px;
    margin: 0 !important;
  }

  &__checkbox {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
  }
}
</style>
