<template>
  <div class="base-mobile-list">
    <div v-for="(item, index) in items" :key="index">
      <v-divider class="mb-4" />
      <v-container class="py-0 mb-3">
        <slot name="item" :item="item" />
      </v-container>
    </div>

    <div v-if="!items.length" class="base-mobile-list__missing-data text-center pt-8">
      {{ $t('list.missing_data') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseMobileList',

  props: {
    items: { type: Array, required: true },
  },
};
</script>

<style lang="scss">
.base-mobile-list {
  &__missing-data {
    opacity: 0.5;
  }
}
</style>
