<template>
  <base-missing-data :icon="$options.IconMissingDataServices" class="tasks-missing-data" :message="message" />
</template>

<script>
import BaseMissingData from '@/components/BaseMissingData.vue';
import IconMissingDataServices from '@/components/MissingDataIcons/MissingDataServices.vue';

export default {
  name: 'ServicesMissingData',

  components: { BaseMissingData },

  props: {
    isArchive: { type: Boolean, default: false },
  },

  computed: {
    message() {
      if (this.isArchive) {
        return this.$t('services.archived_missing_message');
      }

      return this.$t('services.missing_message');
    },
  },

  IconMissingDataServices,
};
</script>
