<template>
  <div class="base-missing-data d-flex flex-column align-center justify-center py-5">
    <span class="d-flex justify-center mb-5">
      <slot name="icon">
        <component :is="icon || defaultIcon" :width="media.isMobile ? 150 : 252" :height="media.isMobile ? 150 : 251" />
      </slot>
    </span>

    <span class="base-missing-data__header d-flex text-center font-weight-regular text-h5 text-sm-h4">
      <slot name="message">
        {{ message }}
      </slot>
    </span>

    <div
      class="base-missing-data__buttons d-flex justify-center mt-10"
      :class="{ 'flex-column align-center': media.isMobile }"
    >
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import IconMissingDataDefault from '@/components/MissingDataIcons/MissingDataDefault.vue';

export default {
  name: 'BaseMissingData',

  inject: ['media'],

  props: {
    icon: { type: Object, default: null },
    message: { type: String, default: 'Missing data' },
    withControls: { type: Boolean, default: false },
  },

  computed: {
    defaultIcon() {
      return IconMissingDataDefault;
    },
  },

  IconMissingDataDefault,
};
</script>

<style lang="scss">
.base-missing-data {
  @media (max-width: map.get($--screens, 'sm')) {
    min-height: 50vh;
  }

  &__header {
    color: rgba($--black-color-0, 0.5);
    height: fit-content;
  }

  &__buttons {
    width: 100%;
  }
}
</style>
