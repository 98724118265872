/* eslint-disable class-methods-use-this */
import client from '@/http/client';
import { download, repackResponseFile } from '@/utils/common';

const BASE_URL = '/services';

class ServicesService {
  getServices({ limit, offset, search, isArchived, uncategorized } = {}, config) {
    return client.get(`${BASE_URL}/for_employees/`, {
      ...config,
      params: {
        limit,
        offset,
        isArchived,
        search,
        uncategorized,
      },
    });
  }

  getCategories({ limit, offset, search } = {}, config) {
    return client.get(`${BASE_URL}/for_employees_categories/`, {
      ...config,
      params: {
        limit,
        offset,
        search,
      },
    });
  }

  getServicesById(serviceId) {
    return client.get(`${BASE_URL}/for_employees/${serviceId}/`);
  }

  createService({
    name,
    shortDescription,
    description,
    initialAmount,
    contractor,
    imageForList,
    imageForRetrieve,
    scripts,
    accessibleForProjects,
    order,
    publicAccess,
    category,
  }) {
    return client.post(`${BASE_URL}/for_employees/`, {
      name,
      shortDescription,
      description,
      initialAmount,
      contractor,
      imageForList,
      imageForRetrieve,
      scripts,
      accessibleForProjects,
      order,
      publicAccess,
      category,
    });
  }

  updateServiceById({
    name,
    shortDescription,
    description,
    initialAmount,
    contractor,
    imageForList,
    imageForRetrieve,
    scripts,
    accessibleForProjects,
    serviceId,
    order,
    publicAccess,
    category,
  }) {
    return client.patch(`${BASE_URL}/for_employees/${serviceId}/`, {
      name,
      shortDescription,
      description,
      initialAmount,
      contractor,
      imageForList,
      imageForRetrieve,
      scripts,
      accessibleForProjects,
      order,
      publicAccess,
      category,
    });
  }

  getServiceCategoryById(categoryId) {
    return client.get(`${BASE_URL}/for_employees_categories/${categoryId}/`);
  }

  createServiceCategory({ name, shortDescription, order, publicAccess }) {
    return client.post(`${BASE_URL}/for_employees_categories/`, {
      name,
      shortDescription,
      order,
      publicAccess,
    });
  }

  updateServiceCategoryById({ name, shortDescription, order, publicAccess, categoryId }) {
    return client.patch(`${BASE_URL}/for_employees_categories/${categoryId}/`, {
      name,
      shortDescription,
      order,
      publicAccess,
    });
  }

  exportService() {
    return client.postRaw(
      `${BASE_URL}/for_employees/xlsx/`,
      {},
      {
        responseType: 'blob',
      }
    );
  }

  async downloadService(fallbackFilename) {
    const response = await this.exportService();

    const { filename, blob } = repackResponseFile(response, fallbackFilename);

    download(filename, blob);
  }

  deleteCategory(categoryId) {
    return client.delete(`${BASE_URL}/for_employees_categories/${categoryId}/`);
  }

  archiveServices({ services, isArchived }) {
    return client.patch(`${BASE_URL}/for_employees/archive/`, {
      services,
      isArchived,
    });
  }

  archiveAllServices({ isArchived }) {
    return client.patch(`${BASE_URL}/for_employees/archive/all/`, {
      isArchived,
    });
  }
}

export default new ServicesService();
