<template>
  <div
    v-if="isCategory"
    class="d-flex align-center services__item services__category pr-4 pl-4 pr-md-6 pl-md-6 pl-md-12"
  >
    <div class="d-flex align-center services__item-name  text-truncate" :class="{ 'pl-md-7': !item.publicAccess }">
      <v-tooltip v-if="item.publicAccess" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mr-2" color="primary" v-bind="attrs" v-on="on">
            mdi-check-decagram
          </v-icon>
        </template>
        <span>{{ $t('services.tooltip_public_category') }}</span>
      </v-tooltip>
      <div>
        <p class="text-h6 ma-0">{{ item.name }}</p>
        <p class="text-caption ma-0">{{ $t('service.order_sort') }}: {{ item.order }}</p>
      </div>
    </div>
    <template>
      <v-spacer />

      <v-btn
        v-if="canUpdate"
        class="mr-0 mr-md-2 text-caption text-body-2 text-uppercase"
        :class="{ 'services__edit-category': !media.isMobile }"
        :to="editCategoryLink"
        :text="!media.isMobile"
        small
        :icon="media.isMobile"
        @click.stop
      >
        <v-icon v-if="media.isMobile" color="primary">
          mdi-pencil
        </v-icon>
        <span v-else>{{ $t('services.button_edit_category') }}</span>
      </v-btn>

      <div>
        <v-icon size="24" color="primary">
          <template v-if="isOpen">
            mdi-chevron-up
          </template>
          <template v-else>
            mdi-chevron-down
          </template>
        </v-icon>
      </div>
    </template>
  </div>

  <div
    v-else-if="media.isMobile && (isServiceWithCategory || isServiceWithoutCategory)"
    :class="{
      'services__with-category': isServiceWithCategory,
      'services__without-category': isServiceWithoutCategory,
    }"
  >
    <v-divider class="mb-4" />
    <v-container>
      <div class="mt-6">
        <v-tooltip v-if="item.publicAccess" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" class="mr-1" v-bind="attrs" v-on="on">
              mdi-check-decagram
            </v-icon>
          </template>
          <span>{{ $t('services.tooltip_public_service') }}</span>
        </v-tooltip>
        <v-btn class="services-mobile-list__name text-body-1 font-weight-medium px-0 text-none" text :to="serviceLink">
          {{ item.name }}
        </v-btn>
      </div>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="12" class="pr-1">
            <view-list-field :label="$t('services.some_description')">
              {{ item.shortDescription }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="12" class="pr-1">
            <view-list-field :label="$t('services.contractor')">
              {{ item.contractor }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="12" class="pr-1">
            <view-list-field :label="$t('services.projects')">
              {{ joinWithLimit(item.accessibleForProjects, 4) }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="12" class="pr-1">
            <view-list-field :label="$t('service.order_sort')">
              {{ item.order }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="d-flex flex-row mt-6">
        <p class="flex-grow-1 services-mobile-list__initial-amount">
          {{ item.initialAmount }}
        </p>
        <div class="d-flex align-end text-caption text--secondary">
          <div class="d-flex align-center">
            {{ $t('label.select') }}

            <base-checkbox
              :input-value="getSelectedStatus(item)"
              class="services-mobile-list__checkbox ml-1 pt-0 mt-0"
              hide-details
              @change="$emit('select-item', item)"
            />
          </div>
        </div>
      </div>
    </v-container>
  </div>

  <router-link
    v-else-if="isServiceWithCategory || isServiceWithoutCategory"
    class="d-flex align-center services__item pr-6 pl-md-12"
    :class="{
      'services__with-category': isServiceWithCategory,
      'services__without-category': isServiceWithoutCategory,
    }"
    :to="serviceLink"
    tag="div"
  >
    <v-row>
      <v-col cols="1" class="pa-4">
        <div class="services__item-text text-truncate text-center">
          {{ item.order }}
        </div>
      </v-col>
      <v-col cols="2" class="pa-4">
        <div class="services__item-text text-truncate" :class="{ 'pl-7': !item.publicAccess }">
          <v-tooltip v-if="item.publicAccess" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on">
                mdi-check-decagram
              </v-icon>
            </template>
            <span>{{ $t('services.tooltip_public_service') }}</span>
          </v-tooltip>
          {{ item.name }}
        </div>
      </v-col>
      <v-col cols="2" class="pa-4">
        <div class="services__item-text text-truncate">
          {{ item.shortDescription }}
        </div>
      </v-col>
      <v-col cols="3" class="pa-4">
        <div class="services__item-text text-truncate">
          {{ joinWithLimit(item.accessibleForProjects, 3) }}
        </div>
      </v-col>
      <v-col cols="2" class="pa-4">
        <div class="services__item-text text-truncate">
          {{ item.contractor }}
        </div>
      </v-col>
      <v-col cols="2" class="pa-4">
        <div class="services__item-text text-end">
          {{ item.initialAmount }}
        </div>
      </v-col>
    </v-row>
  </router-link>

  <div
    v-else-if="isButtonAddService && canUpdate"
    class="d-flex align-center justify-center services__item services__button-add-service"
  >
    <v-btn class="text-capitalize font-weight-bold" text :to="serviceCreateLink">
      {{ $t('services.button_add_service') }}
    </v-btn>
  </div>
</template>

<script>
import ViewListField from '@/components/ViewListField.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';

import joinWithLimit from '@/utils/joinWithLimit';
import {
  CATEGORY,
  CATEGORY_BUTTON_ADD_SERVICE,
  EMPTY_CATEGORY,
  SERVICE_WITH_CATEGORY,
  SERVICE_WITHOUT_CATEGORY,
} from '@/constants/serviceEntity';
import { SERVICES_CATEGORY_EDIT, SERVICES_DETAILED, SERVICES_CREATE } from '@/constants/routes';

export default {
  name: 'ServicesItem',

  components: { ViewListField, BaseCheckbox },

  inject: ['media'],

  props: {
    item: { type: Object, required: true },
    isOpen: { type: Boolean, default: false },
    canUpdate: { type: Boolean, required: true },
    getSelectedStatus: { type: Function, required: true },
  },

  computed: {
    isCategory() {
      return this.item.type === CATEGORY || this.item.type === EMPTY_CATEGORY;
    },
    isServiceWithCategory() {
      return this.item.type === SERVICE_WITH_CATEGORY;
    },
    isServiceWithoutCategory() {
      return this.item.type === SERVICE_WITHOUT_CATEGORY;
    },
    isButtonAddService() {
      return this.item.type === CATEGORY_BUTTON_ADD_SERVICE;
    },
    editCategoryLink() {
      return {
        name: SERVICES_CATEGORY_EDIT,
        params: { id: this.item.id },
        query: { prevPage: this.page },
      };
    },
    serviceLink() {
      return {
        name: SERVICES_DETAILED,
        params: { id: this.item.id },
        query: { prevPage: this.page },
      };
    },
    serviceCreateLink() {
      return {
        name: SERVICES_CREATE,
        query: { prevPage: this.page, category: this.item.categoryId, categoryName: this.item.categoryName },
      };
    },
  },

  methods: {
    joinWithLimit,
  },
};
</script>

<style lang="scss">
.services {
  &__category {
    background: $--project-expansion-panel-background;
    letter-spacing: 0.05px;
  }

  &__with-category {
    background: $--blue-color-60;
  }

  &__button-add-service {
    background: $--blue-color-70;

    .v-btn__content {
      color: #00008d;
      font-size: 16px;
    }
  }

  &__category,
  &__with-category,
  &__button-add-service {
    border-bottom: 1px solid rgba($--blue-color-20, 0.15);
  }

  &__with-category,
  &__without-category {
    letter-spacing: 0.08px;
  }
}

.services-mobile-list {
  &__name {
    color: $--primary-color !important;
    min-width: 0 !important;
  }

  &__initial-amount {
    font-weight: $--font-weight-bold;
    font-size: 16px;
    line-height: 24px;
    margin: 0 !important;
  }

  &__checkbox {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
  }
}
</style>
