<template>
  <v-treeview
    ref="treeview"
    v-model="active"
    expand-icon=""
    color="#1E1E22"
    :items="services"
    :open.sync="open"
    :open-all="openAll"
    selected-color="#002FFF"
    item-disabled="locked"
    open-on-click
    :selectable="!media.isMobile"
    return-object
    activatable
    transition
  >
    <template #label="{ item }">
      <services-item
        :item="item"
        :is-open="checkItemIsOpen(item)"
        :get-selected-status="getSelectedStatus"
        :can-update="canUpdate"
        @select-item="selectItem"
      />
    </template>
  </v-treeview>
</template>

<script>
import ServicesItem from '@/components/Services/Item.vue';
import { createModelWrapper } from '@/utils/components';

import { isEqual } from '@/utils/common';

export default {
  name: 'ServicesList',

  components: { ServicesItem },

  inject: ['media'],

  model: {
    prop: 'selectedItems',
    event: 'update:selectedItems',
  },

  props: {
    services: { type: Array, required: true },
    selectedItems: { type: Array, default: () => [] },
    openAll: { type: Boolean, default: false },
    canUpdate: { type: Boolean, required: true },
  },

  data() {
    return {
      open: [],
      active: [],
    };
  },

  computed: {
    selectedItemsModel: createModelWrapper('selectedItems', 'update:selectedItems'),
  },

  watch: {
    openAll(isOpenAll) {
      if (isOpenAll) {
        this.open = this.services.map(item => item.isCategory && item.id);
      } else this.open = [];
    },
    active(newValue) {
      this.selectedItemsModel = newValue;
    },
    selectedItemsModel(selectedItems) {
      if (!isEqual(selectedItems, this.active)) {
        this.active = this.selectedItemsModel;
      }
    },
  },

  methods: {
    checkItemIsOpen(item) {
      return !!this.open.find(openItemId => openItemId === item.id);
    },
    getSelectedStatus(item) {
      return this.selectedItemsModel.findIndex(usage => usage.id === item.id) !== -1;
    },
    selectItem(item) {
      if (this.active.findIndex(usage => usage.id === item.id) !== -1) {
        this.active = this.active.filter(activeItem => activeItem.id !== item.id);
      } else {
        this.active.push(item);
      }
    },
  },
};
</script>
