<template>
  <div class="list-loading d-flex align-center justify-center">
    <v-progress-circular indeterminate :width="3" color="primary" />
  </div>
</template>

<script>
export default {
  name: 'ListLoading',
};
</script>

<style lang="scss">
.list-loading {
  height: 455px;
}
</style>
