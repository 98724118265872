<template>
  <v-pagination
    ref="pagination"
    color="secondary"
    class="base-pagination mt-10 text--primary"
    v-bind="$attrs"
    v-on="$listeners"
    @input="scrollTop"
  ></v-pagination>
</template>

<script>
export default {
  name: 'BasePagination',

  mounted() {
    // Vuetify при разрешении 320px не отображает текущую страницу
    // Данный хак ставит минимальное количество отображаемых страниц в пагинации vuetify
    this.$watch(
      () => {
        return this.$refs.pagination.maxButtons;
      },
      newValue => {
        if (newValue < 5) {
          this.$refs.pagination.maxButtons = 5;
        }
      }
    );
  },

  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
.base-pagination {
  .v-pagination__item {
    box-shadow: none;
    color: rgba($--secondary-color, 0.5) !important;
  }

  .v-pagination__item--active {
    color: $--blue-color-5 !important;
  }

  .v-pagination__navigation {
    background-color: $--blue-color-5 !important;

    .v-icon {
      color: #fff !important;
    }
  }
}
</style>
