<template>
  <v-select
    v-model="sortModel"
    :item-value="item => item"
    hide-details
    class="mobile-sort-menu"
    :items="sortableList"
    :placeholder="$t('button.sort_by')"
  >
  </v-select>
</template>

<script>
// Constants
import { DECREASING } from '@/constants/sortStatuses';

export default {
  name: 'MobileSortMenu',

  props: {
    sortList: { type: Array, default: () => [] },
    sort: { type: Object, default: () => ({}) },
  },

  data: () => ({
    isOpen: false,
    model: 0,
  }),

  computed: {
    sortModel: {
      get() {
        return this.sort;
      },

      set(newSort) {
        this.$emit('update:sort', newSort);
      },
    },

    isSortHasValue() {
      return this.sort?.value;
    },

    sortableList() {
      return this.sortList.filter(sort => sort.sortable !== false);
    },
  },

  watch: {
    isOpen() {
      if (this.isOpen) this.selectedSort = this.sort;
    },

    selectedSort(newSort) {
      this.$emit('update:sort', { ...newSort, status: DECREASING });
    },
  },

  methods: {
    closeMenu() {
      this.isOpen = false;
    },

    getSortText(item) {
      return item.sortText || item.text;
    },
  },
};
</script>

<style lang="scss">
.mobile-sort-menu {
  margin-top: 0 !important;
  padding-top: 0 !important;

  .v-select__selections {
    padding: 16px 0 12px 12px;

    input {
      padding: 0;
    }
  }

  .v-input__append-inner {
    margin-top: 16px !important;
  }

  .v-select__selection {
    margin: 0;
  }

  .v-input__slot:before {
    border-color: rgba($--blue-color-20, 0.15) !important;
  }
}
</style>
